export const environment = {
  name: 'production',
  production: true,
  baseUrl: 'https://api.sipm.toyota.com',
  clientId: 'f32ab5f6-d479-407b-9cae-2c8eb77cf504',
  authority:
    'https://login.microsoftonline.com/8c642d1d-d709-47b0-ab10-080af10798fb/',
  redirectUri: 'https://sipm.toyota.com',
  postLogoutRedirectUri: 'https://sipm.toyota.com//dashboard',
  scope: 'https://api.sipm.toyota.com//SIPMAPI.All',
};